import { sampleSize } from 'lodash'
import { darken } from 'polished'
import React, { useContext, useMemo } from 'react'
import styled, { ThemeContext } from 'styled-components'

interface ArrowProps {
  isGreen?: boolean
}

const CircleArrow = ({ isGreen }: ArrowProps) => {
  const themeContext = useContext(ThemeContext)

  return (
    <svg width='24' height='24' viewBox='0 0 43 43' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='21.7607' cy='21.2841' r='21' fill='#C4C4C4' />
      <circle cx='21.7607' cy='21.2841' r='21' fill='url(#paint0_linear)' />
      <path
        d='M29.7607 15.7841L18.7607 26.7841L13.7607 21.7841'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <defs>
        <linearGradient
          id='paint0_linear'
          x1='21.7607'
          y1='0.284058'
          x2='21.7607'
          y2='42.2841'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={isGreen ? themeContext.colors.green : themeContext.colors.purple} />
          <stop offset='1' stopColor={darken(0.05, isGreen ? themeContext.colors.green : themeContext.colors.purple)} />
        </linearGradient>
      </defs>
    </svg>
  )
}

interface Props {
  points: string[]
  quantity: number
  isGreen?: boolean
}

export default ({ points, quantity }: Props) => {
  const x = useMemo(
    () =>
      sampleSize(points, quantity).map(el => (
        <Row key={el}>
          <CircleArrow isGreen />
          <Point>{el}</Point>
        </Row>
      )),
    [points]
  )

  return <>{x}</>
}

const Row = styled.div`
  display: inline-grid;
  grid-template-columns: 24px auto;
  grid-auto-flow: column;
  gap: 1.5rem;
`

const Point = styled.p`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.grey700};

  @media all and (max-width: 28.125em) {
    font-size: 0.875rem;
  }
`
